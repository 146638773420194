.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}
@media only screen and (min-width:1245px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media only screen and (max-width:1244px){
  .collapse:not(.show) {
    display: none !important;
  }
  .navbar-expand-lg .navbar-toggler{
    display: block !important;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: column;
  }
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }
}
@media only screen and (min-width:991.5px) and (max-width:1244px){
  nav.navbar.navbar-light.navbar-expand-lg .container-fluid {
    display: block;
    position: relative;
  }
  nav.navbar.navbar-light.navbar-expand-lg .container-fluid span.navbar-brand{
    display: inline-block !important;
  }
  nav.navbar.navbar-light.navbar-expand-lg .container-fluid button.navbar-toggler {
    display: inline-block !important;
    margin-left: auto !important;
    position: absolute;
    right: 0;
  }
}