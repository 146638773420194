// Here you can add other styles
.thumbnail {
  width: 100%;
  height: 225px;
}

.updateThumbnail {
  width: 40px;
  height: 30px;
}

.leftRightBtn {
  position: relative;
  width: 100%;
  background-color: #fff;
  height: 30px;
}

.rightBtn {
  position: absolute;
  right: 0;
}

.newBusinessSignUpVideo {
  width: 100%;
}

.tox-notification.tox-notification--in.tox-notification--warning {
  display: none;
}

.tinymce-editor-container {
  height: 600px;
}

.description-box {
  padding: 10px;
}

.remove-field {
  position: relative;
  top: 35%;
}

.business-services-card .card-body {
  overflow-x: auto;
}

.errorMessage {
  color: red;
}

.col-md-6.map-image {
  margin-bottom: 15px;
}

.f-right {
  float: right;
}

.bookedServices span {
  color: #0000ff;
  cursor: pointer;
}

.f-left {
  float: left;
}

.ml-5 {
  margin-left: 5px;
}

a {
  text-decoration: none !important;
}

.loaderBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #0000155c;
}

.loaderSpinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

p.package-active {
  background: green;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

p.package-delete {
  background: red;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

p.package-inactive {
  background: #f0ad4e;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  margin-top: 15px;
  cursor: pointer;
}

.read-icon {
  color: #2eb85c;
  width: 25px !important;
  height: 25px !important;
}

@media (max-width: 600px) {
  .px-3 {
    padding-right: 0rem !important;
    padding-left: 0rem !important;
    overflow: hidden;
  }

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  img {
    width: 100%;
  }

  .mf-right {
    float: right;
  }

  .ml-1 {
    position: relative;
    left: 1px;
  }

  .mr-1 {
    position: relative;
    right: 1px;
  }

  .business_container {
    padding: 0px 10px;
  }
}

.phone_country_code_container {
  width: 30%;
  margin-right: 5px;
}

.phone_country_code_container select {
  padding: 6px 3px !important;
}

.phone_input_container {
  display: flex;
}

.radio_label {
  margin-right: 10px;
}

.submit_button {
  width: 100%;
}

label {
  font-weight: 600;
}

.alert-danger {
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 0;
}

.custom_hr {
  margin: 8px 0px;
  border-top: 3px solid;
}

.business-list-row {
  cursor: pointer;
}

.business-list-row:hover {
  background-color: #c9c9c9;
  transition: 0.5s;
}

.capital_letter {
  text-transform: capitalize;
}

.margin_top_2_px {
  margin-top: 2px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
  /* Firefox */
  -webkit-appearance: textfield;
  /* Safari and Chrome */
}

.list ul:nth-child(odd) {
  background-color: #ddd;
}

.list ul:nth-child(even) {
  background-color: #fff;
}

.list {
  width: 100%;
}

/* big */
@media screen and (min-width: 600px) {
  .list {
    display: table;
    margin: 1em;
  }

  .list ul {
    display: table-row;
  }

  .list ul:first-child li {
    background-color: #3c4b64;
    color: #fff;
  }

  .list ul>li {
    display: table-cell;
    padding: 0.5em 1em;
  }
}

/* small */
@media screen and (max-width: 599px) {
  .list ul {
    border: solid 1px #ccc;
    display: block;
    list-style: none;
    margin: 0em;
    padding: 0.5em 0.5em;
  }

  .list>ul:first-child {
    display: none;
  }

  .list ul>li {
    display: block;
    padding: 0.25em 0;
  }

  .list ul:nth-child(odd)>li+li {
    border-top: solid 1px #ccc;
  }

  .list ul:nth-child(even)>li+li {
    border-top: solid 1px #eee;
  }

  .bookingList p {
    margin-top: 0;
    margin-bottom: 0.3rem;
  }

  .list ul>li:before {
    color: #000;
    content: attr(data-label);
    display: inline-block;
    font-size: 75%;
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: top;
    width: 50%;
  }

  .bookingList ul>li:before {
    color: #000;
    content: attr(data-label);
    display: inline-block;
    font-size: 100%;
    font-weight: bold;
    text-transform: capitalize;
    vertical-align: top;
    width: 50%;
  }

  // .list p {
  //   margin:-1em 0 0 50%;
  // }
}

/* tiny */
@media screen and (max-width: 349px) {
  .list ul>li:before {
    display: block;
  }

  .list p {
    margin: 0;
  }
}

.status_buttons {
  margin-right: 5px;
}

.status_button_container {
  text-align: right;
  margin-top: 8px;
}

.current_status_container {
  margin-right: 20px;
}

.current_status_label {
  font-weight: 500;
}

.current_status {
  font-weight: 700;
  text-transform: uppercase;
}

.previewImage {
  height: auto;
  width: 100px;
}

.primary_check label {
  font-size: 12px;
}

.delete_image {
  position: absolute;
  right: -17px;
  top: -13px;
  color: red;
}

.delete_image .icon {
  width: 19px !important;
  height: 26px !important;
  cursor: pointer;
}

.display-none {
  display: none !important;
}

.list {
  margin: 10px 0px;
}

.mr-10 {
  margin-right: 10px;
}

.notes-card .card-header {
  background-color: #3c4b64;
  display: flex;
  align-items: center;
  border-bottom: 0;
}

.notes-card .user-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.num-box h3 {
  font-size: 25px;
  color: #3c4b64;
}

.num-box {
  background-color: #b5b5b5;
  color: #fff;
  width: 60px;
  text-align: center;
  border-radius: 4px 0px 0px 0px;
  padding: 17px 0px;
}

.notes-card .create-box,
.notes-card .update-box {
  display: flex;
  align-items: center;
  gap: 15px;
}

.col-md-6.img-employee img {
  height: 145px;
}

.notes-card .create-box .img img,
.notes-card .update-box .img img {
  max-width: 100px;
  border-radius: 50%;
}

.col-md-6.img-employee {
  float: left;
}

.notes-card .create-box .content .create-name,
.notes-card .update-box .content .update-name {
  margin-bottom: 0px;
  color: #fff;
}

.notes-card .create-box .content .create-date,
.notes-card .update-box .content .update-date {
  margin-bottom: 0px;
  color: #b7b7b7;
  text-align: left;
  position: relative;
}

.notes-card .edit-btn {
  border: 2px solid #3c4b64;
  background-color: transparent !important;
  color: #3c4b64;
}

.notes-card .delete-btn {
  background-color: #3c4b64;
  border-color: #3c4b64;
}

.notes-card .delete-btn:hover {
  color: #fff;
}

.list.paginationBar ul li a {
  color: #fff !important;
}

.col-md-6.sort-drop-down {
  float: left !important;
}

.col-md-6.sort-text {
  float: left !important;
  padding-right: 10px;
  text-align: right;
  padding-top: 5px;
}

.card-images {
  float: left;
}

.list.paginationBar {
  background: #3c4b64;
}

button#imageLoadMore {
  margin-top: 20px;
}

.col-md-12.button-load-more {
  text-align: center;
}

.card-images button.btn.image-edit-btn {
  background: #303c54;
  color: #fff;
  margin-left: auto;
  display: block;
}

.card-images button.btn.success-btn {
  background: #10a910;
  color: #fff;
  margin-left: auto;
  display: block;
}

li.selected {
  background: #7c879a !important;
}

.list.paginationBar ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  justify-content: center;
  margin-bottom: 0;
  background-color: #3c4b64;
}

@media only screen and (max-width: 599px) {
  .list.paginationBar ul li {
    padding: 10px 10px;
    border-top: none !important;
  }

  .list.paginationBar ul>li:before {
    display: none;
  }

  .col-md-6.filter {
    float: left;
    z-index: 999;
    position: relative;
    margin-left: 8px;
    margin-top: 12px;
    width: 96%;
  }
}

@media screen and (max-width: 767px) {
  .notes-card .card-header {
    align-items: flex-start;
    gap: 5px;
  }

  .num-box {
    border-radius: 4px 0px 50px 0px;
    width: 40px;
    padding: 7px 0px;
  }

  .col-md-6.img-employee {
    float: left;
    margin-right: 10px;
  }

  .notes-card .user-detail {
    gap: 20px;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 12px;
    padding-top: 10px;
  }
}

@media screen and (max-width: 550px) {

  .notes-card .create-box .content .create-name,
  .notes-card .update-box .content .update-name {
    font-size: 18px;
  }

  .notes-card .create-box .content .create-date,
  .notes-card .update-box .content .update-date {
    font-size: 13px;
  }
}

@media screen and (max-width: 599px) {
  li.serial-number {
    margin-top: 30px;
  }
}

.delete-btn {
  color: #fff;
}

.notes-card {
  margin-bottom: 15px;
}

.notes-card h5,
.h5 {
  font-size: 1.1rem;
}

.item-right {
  width: 70%;
  margin-left: 10px;
}

.strong_border hr {
  border: 3px solid;
  margin: 1.3rem 0px;
}

.small-text-for-list {
  color: #ddd;
  font-weight: 500;
}

.sub-category-form-row {
  background-color: #ddd;
  padding: 15px 10px;
}

.business-services-card .card-header {
  padding: 10px 15px;
}

.review-card .card-header {
  padding: 10px 15px;
}

.nav-link.active {
  font-weight: 700;
}

.show-border-on-mobile {
  display: none;
}

.cart-service .modal-content {
  background-color: transparent;
  border: none;
}

.cart-service button.btn-close {
  margin-left: auto;
  background-color: #fff;
  opacity: 1;
  padding: 7px;
  border-radius: 50%;
  height: 8px;
  width: 8px;
  font-size: 12px;
  margin-bottom: 10px;
}

.price-container {
  background: #f8f8f8;
  border-radius: 10px 10px 0px 0px;
  padding: 0px 15px;
  position: relative;
}

.modal-links {
  display: flex;
  gap: 0px 20px;
  flex-wrap: wrap;
}

.topDateTimeSection {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  padding-top: 15px;
}

.topDateTimeSection p.label {
  margin-bottom: 5px;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-left: -10px;
  width: fit-content;
  padding-right: 20px;
  color: #777;
}

.topDateTimeSection p.date {
  margin-bottom: 0;
}

.object-show .modal-dialog {
  max-width: max-content;
}

.guestDetails {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.finishOnSection p.label {
  padding-right: 10px;
  margin-right: -10px;
  margin-left: auto;
  padding-left: 20px;
}

.finishOnSection {
  text-align: right;
  padding-right: 10px;
}

.requestOnSection {
  padding-left: 10px;
}

.time-tag-btn {
  background: #dceec9;
  border: none;
  border-radius: 50px;
  font-family: "Jost", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #1e0201;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90%;
}

button#imageLoadMore {
  color: #ebedef;
  background: #3c4b64;
}

.col-md-12.card-images.col-12.business-detail-box {
  padding: 0 5px;
}

.col-md-6.filter {
  float: left;
}

.price-tag-list {
  margin: 0;
  padding: 8px 0 0;
  max-height: 327px;
  overflow-y: auto;
}

.price-list-wrapper:last-child {
  border: unset;
  margin: 10px 0 0;
}

.price-list-wrapper {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 10px 0;
  padding: 0 0 15px;
  border-bottom: 1px solid #eaeaea;
}

.flex-service-detail {
  display: flex;
  align-items: baseline;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1e0201;
  min-width: 0;
}

.category-service-count {
  margin: 0 4px 0 0;
}

.category-flex-name-box {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

.category-service-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1e0201;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0 5px;
}

.category-service-time {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 10px;
  color: #9e9e9e;
}

.category-flexbox {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.category-price-tag {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: right;
  color: #1e0201;
  display: block;
}

.price-tag-wrapper {
  background: #f1fce5;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 20px 15px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  color: #1e0201;
  position: relative;
}

.price-tag-wrapper:after {
  height: 16px;
  top: 100%;
  background: linear-gradient(-135deg, #f1fce5 8px, transparent 0) 0 8px,
    linear-gradient(135deg, #f1fce5 8px, transparent 0) 0 8px;
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 16px 16px;
}

.price-tag-wrapper:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
}

li.price-list-wrapper {
  background: #f8f8f8 !important;
  float: left;
  width: 100%;
  margin: 0px !important;
  padding: 10px !important;
  display: flex !important;
  justify-content: space-between;
}

.total-amount,
.pay-now-amount,
.pay-shop-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pay-now-amount {
  font-size: 20px;
  border-top: 1.5px dashed #e4e4e4;
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #ff5647;
}

.timing-location-flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 0 0;
}

div.card-header>div>div.create-box>div.img img {
  height: 100px;
}

.modal-dialog.modal-dialog-centered.cart-service {
  width: 350px;
}

.hiddenClass {
  display: none;
}

ul.business-list-row li {
  position: relative;
}

ul.price-tag-list ul {
  width: 100%;
  display: contents;
  padding: 0;
}

.bookingServiceBox {
  position: absolute;
  left: -20vw;
  top: -50%;
  z-index: 999;
  background-color: #0009;
  padding: 20px 20px 30px;
  border-radius: 10px;
}

.bookingList ul.business-list-row:first-child {
  margin-top: 0;
}

.bookingList ul.business-list-row {
  cursor: auto;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .show-action {
    display: flex;
    gap: 10px;
  }
}

@media only screen and (max-width: 599px) {
  ul.price-tag-list {
    padding: 0;
    margin: 0;
  }

  ul.price-tag-list ul {
    padding: 0;
    margin: 0;
    border: none;
  }

  li.price-list-wrapper {
    margin: 0px !important;
    padding: 10px !important;
  }

  li.price-list-wrapper:before {
    display: none !important;
  }

  ul.price-tag-list {
    border-radius: 10px 10px 0 0;
  }

  .price-container {
    background: transparent;
    padding: 0px 0px;
  }

  .bookingServiceBox {
    position: absolute;
    left: 0%;
    top: -100%;
    z-index: 999;
    background-color: #0000006b;
    padding: 20px 20px 40px;
    border-radius: 6px;
    width: 100%;
  }

  .modal-dialog.modal-dialog-centered.cart-service {
    width: 100%;
    min-width: 310px;
  }
}

@media screen and (max-width: 390px) {
  .bookingServiceBox {
    padding: 10px 10px 30px;
  }
}

@media screen and (max-width: 375px) {
  .modal-dialog.modal-dialog-centered.cart-service {
    width: 100%;
    min-width: 260px;
  }
}

@media screen and (max-width: 750px) {
  .show-border-on-mobile {
    display: block;
  }

  .employee .col-md-4 {
    margin-top: 5px;
  }
}

.booking-accept {
  background-color: #4caf50;
  color: #fff;
  padding: 0px 5px 3px;
  border-radius: 4px;
  margin-bottom: 7px;
  text-align: center;
  cursor: pointer;
}

.booking-reject {
  background-color: #f44336;
  color: #fff;
  text-align: center;
  padding: 0 5px 3px;
  margin-bottom: 7px;
  border-radius: 4px;
  cursor: pointer;
}

ul.business-list-row {
  position: relative;
}

.close-button {
  position: absolute;
  left: 0;
  top: 0;
  background-color: red;
  color: #fff;
  padding: 3px 12px 10px 8px;
  border-radius: 0px 0px 30px 0px;
  font-weight: 600;
  cursor: pointer;
}

.bookingList ul.business-list-row li:first-child {
  margin-top: 30px;
  padding-top: 30px;
}

.custom-label {
  font-weight: 600;
}

.pad-t-5 {
  padding-top: 5px;
}

.separator {
  padding-bottom: 10px;
}

.service-meta-data .label {
  font-weight: 600;
}

.p-l-13 {
  padding-left: 13px;
}

.service-meta-data {
  font-size: 17px;
}

.service-meta-data .card-title {
  font-size: 17px;
}

.or-text {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}

@media screen and (max-width: 750px) {
  .mobile-border {
    border-top: 3px solid #b1b7c1;
    padding-top: 5px;
  }

  .title-container {
    display: block;
    padding-bottom: 10px;
  }
}

.update-button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  flex-wrap: wrap;
}

.save-search-meta-data-button,
.save-service-button {
  display: block;
  margin: auto;
  width: fit-content;
}

@media screen and (max-width: 370px) {
  .update-button-container {
    flex-direction: column;
  }
}

.card img {
  height: 170px;
  object-fit: cover;
}

.new-business-request-image {
  color: #0000ff;
  cursor: pointer;
}

.card-highlight {
  border: 4px solid #ec4848 !important;
}

.card-highlight .form-text {
  color: red !important;
}

.highlight-primary-image {
  border: 4px solid #919191;
}

.sub-heading-service-meta-data {
  color: #836464;
}

.padding-0 {
  padding: 0px;
}

.new-business-sign-up-request-modal .modal-dialog {
  width: 100%;
  max-width: 98%;
}

.new-business-sign-up-request-modal .modal-dialog img {
  height: 300px;
  object-fit: cover;
}

.new-business-sign-up-request-modal .modal-dialog a .download-icon {
  position: relative;
}

.new-business-sign-up-request-modal .modal-dialog a svg.nav-icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  height: 50px;
  width: 50px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px #363636;
}

@media screen and (max-width: 767px) {
  .new-business-sign-up-request-modal .modal-dialog {
    max-width: 96%;
  }
}

@media screen and (max-width: 480px) {
  .new-business-sign-up-request-modal .modal-dialog img {
    height: 400px;
  }

  .new-business-sign-up-request-modal .modal-dialog a svg.nav-icon {
    height: 40px;
    width: 40px;
  }
}

@media screen and (max-width: 370px) {
  .new-business-sign-up-request-modal .modal-dialog img {
    height: 350px;
  }
}

@media screen and (min-width: 601px) and (max-width: 767px) {
  .new-business-sign-up-request-modal .modal-dialog img {
    height: 670px;
  }
}

p.client-name {
  font-size: 14px;
}

@media only screen and (max-width: 767px) {
  p.client-name {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.range-box-parent {
  display: flex;
  gap: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 20px;
  padding-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
}

.single-range-box {
  width: 32%;
}

.range-value-remove-btn {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
  position: relative;
}

.range-value-box {
  width: 100%;
}

.range-remove-btn {
  font-size: 15px;
  padding: 6px 10px;
  color: #fff;
  position: relative;
  top: 32px;
}

.for-mob {
  display: none;
}

@media only screen and (max-width: 991px) {
  .range-value-remove-btn {
    flex-direction: column;
    align-items: flex-end;
  }

  .range-remove-btn {
    top: 0px;
  }

  .for-mob {
    display: block;
  }

  .for-desk {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .single-range-box {
    width: 100%;
  }

  .range-box-parent {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1245px) {
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}

.video-container video,
.video-container img {
  height: 220px;
  object-fit: contain;
  max-width: 100%;
  width: 100%;
  background: #000;
}

.video-container img {
  background: #fff;
}

// Add new css


.cardList ul:nth-child(odd) {
  background-color: #fff;
}

.cardList ul:nth-child(even) {
  background-color: #fff;
}

.cardList {
  width: 100%;
}

/* big */
@media screen and (min-width:600px) {

  .cardList {
    display: table;
    margin: 1em;
  }

  .cardList ul {
    display: table-row;
  }

  .cardList ul:first-child li {
    background-color: #3c4b64;
    color: #fff;
  }

  .cardList ul>li {
    display: table-cell;
    padding: .5em 1em;
  }

}

/* small */


.cardList ul {
  border: solid 1px #ccc;
  display: block;
  list-style: none;
  margin: 0em;
  padding: .5em 0.5em;
}

.cardList>ul:first-child {
  display: none;
}

.cardList ul>li {
  display: block;
  padding: .25em 0;
}

.cardList ul:nth-child(odd)>li+li {
  border-top: solid 1px #eee;
}

.cardList ul:nth-child(even)>li+li {
  border-top: solid 1px #eee;
}

.cardList p {
  margin-top: 0;
  margin-bottom: 0.3rem;
}

.cardList ul>li:before {
  color: #000;
  content: attr(data-label);
  display: inline-block;
  font-size: 75%;
  // font-weight:bold;
  text-transform: capitalize;
  vertical-align: top;
  width: 50%;
}

.cardList ul>li:before {
  color: #000;
  content: attr(data-label);
  display: inline-block;
  font-size: 100%;
  // font-weight: bold;
  text-transform: capitalize;
  vertical-align: top;
  width: 50%;
}

@media screen and (max-width:349px) {
  .cardList ul>li:before {
    display: block;
  }

  .cardList p {
    margin: 0;
  }
}

.cardList {
  margin: 10px 0px;
}

ul.card-list-row li.serial-number {
  margin-top: 30px;
}

ul.card-list-row li {
  position: relative;
}

ul.price-tag-list ul {
  width: 100%;
  display: contents;
  padding: 0;
}

.cardList ul.card-list-row:first-child {
  margin-top: 0;
}

.cardList ul.card-list-row {
  cursor: auto;
  margin-top: 10px;
  position: relative;
}

.list.cardList>ul.card-list-row {
  width: 49% !important;
}

.list.cardList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media only screen and (max-width: 1199px) {
  .list.cardList>ul.card-list-row {
    width: 100% !important;
  }
}

ul.card-list-row {
  position: relative;
}

.timing-table {
  width: 100%;
  border-collapse: collapse;
}

.timing-table th, .timing-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.timing-table th {
  background-color: #f2f2f2;
  text-align: left;
}

.timing-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.timing-table tr:hover {
  background-color: #ddd;
}